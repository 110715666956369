.drag-transform-ui-container {
  position: absolute;
  z-index: 61;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;

  &__body {
    background: hsl(var(--bg-h), var(--bg-s), var(--bg-l));
    border: 1px solid black;
    border-radius: 4px;
    opacity: 0.8;
    padding-bottom: 10px;
  }
}