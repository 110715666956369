.file-input {
  height: 100%;

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 0px 10px;
    font-size: 12px;
    color: var(--text-color-light);
    outline: none;
    border-radius: 4px;
    cursor: pointer;

    &--active {
      position: relative;
      z-index: 3;
      background-color: rgba(#353844, $alpha: 0.6);
    }

    &--accept {
      background-color: #353844;
    }

    &--reject {
      background-color: rgba(#ff1744, .35);
    }

    svg {
      margin-bottom: 8px;
    }

    p {
      max-width: 130px;
    }

    .input-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
