/************* - mixins & variables -******************************/

$font-size-unit-time: 16px;
$font-size-measurement: 16px;
@mixin flex($direction: row, $j-content: center, $a-items: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $j-content;
    align-items: $a-items;
}

/**************************** - styles - ******************************/

.time-code {
    @include flex(row, flex-start, center);
    font-size: $font-size-unit-time;
    font-weight: 400;
    line-height: 1;
    user-select: none;

    &.is-timeline {
      font-size: 20px!important;
      & .unit__input {
        font-size: 20px!important;
        width: 11px;
      }

      & .time-code__box{
        width: 28px;
      }
    }

    &:hover {
        cursor: default;
    }

    &__container{
        @include flex(row, space-between, center);
        padding: 8px 8px 8px 6px;
        border-radius: 4px;
        height: 32px;
        border: 1px solid transparent;
        margin-left: 4px;
        margin-right: 6px;

        &.active, &:hover{
          border: 1px solid var(--border-color-light);
        }

        &.active{
          background-color: var(--card-background);
        }

        &:hover {
            cursor: text;
        }
    }

    &__control {
        @include flex(column, space-between, center);
    }

    .control {
        &__arrow-box {
            @include flex(column, center, center);
            width: 23px;
            height: 15px;
            cursor: pointer;
        }
        &__increase, &__decrease {
            cursor: pointer;
            border-bottom: 1px solid var(--text-color);
            border-left: 1px solid var(--text-color);
            transition: transform 0.25s;
            width: 5px;
            height: 5px;
        }
        &__increase {
            transform: rotate(135deg);
        }
        &__decrease {
            transform: rotate(-45deg);
        }
    }

    &__menu {
        position: relative;
        margin-left: 4px;
        color: gray;
        @include flex(row, center, flex-end);
        font-size: $font-size-measurement;
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
            svg, &, div {
              fill: var(--activity-color);
              color: var(--activity-color);
            }
        }

        &.active {
            color: var(--activity-color);
            transition: all .3s;
        }
    }
    &__unit {
        &.pointer{
            cursor: pointer;
        }

        .select {
            background: #577BA2;
            color: var(--text-color)!important
        }
    }
    &__box{
        display: flex;
        align-items: baseline;
        width: 23px;
    }

    .unit {
        &__input::-webkit-inner-spin-button{
            appearance: none;
            margin: 0;
        }
        &__input {
            -moz-appearance:textfield;
            display: block;
            width: 9px;
            height: 20px;
            padding: 0;
            margin: 0;
            font-size: $font-size-unit-time;
            outline: none !important;
            border: none!important;
            background: transparent;
            color: var(--text-color);
            caret-color: var(--text-color)!important;

            &.gray {
              color: var(--text-color-light);
            }
            &.is-timeline {
              font-size: 20px;
            }
        }

        &__text {
            &.gray {
              color: var(--text-color-light);
            }
        }
    }

    &__dropdown-menu-item {
        position: relative;
        height: 50px;
        padding: 0 10px;
        cursor: pointer;

        &.hidden {
          display: none;
          opacity: 0;
          visibility: hidden;
        }
    }
    &__dropdown-menu-title {
        margin-left: 14px;
        margin-right: 10px;
        color: var(--border-color-light);
    }
    &__dropdown-menu-checked {
        position: absolute;
        display: none;
        width: 8px;
        height: 5px;
        border-bottom: 1px solid var(--text-color);
        border-left: 1px solid var(--text-color);
        transform: rotate(-45deg);
        left: 6px;
        top: 7px;

        &.active {
            display: block;
        }
    }

}