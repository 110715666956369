.editor-settings {
  color: var(--text-color);
  padding: 0 15px 10px;
  height: 100%;
  background: black;
  overflow: hidden;

  .MuiIconButton-label {
    color: var(--text-color)
  }

  &__head {
    &-title {
      font-size: 24px;
      text-align: center;
    }
  }

  &__footer {
    padding: 15px 0;
    gap: 13px;
    border-top: 1px solid var(--background-color);
    .btn {
      width: 105px;
    }
  }

  h2,h3 {
    text-align: center;
    color: var(--text-color);
    margin: 0;
    margin-bottom: 8px;
  }

  &__close {
    height: 24px;
    text-align: right;
    cursor: pointer;
    padding-top: 10px;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: #9d9d9d;
      transition: all .3s;
    }
  }

  &__body {
    height: calc(100% - 140px);
    margin: 10px 0 20px;
  }
  &__menu {
    min-width: 250px;
    min-height: 100%;
    background: var(--background-color);
    border-right: 5px solid black;
    &-item {
      padding: 10px 10px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        background: var(--text-color-light);
      }
      &.active {
        background: grey;
        &:hover {
          background: grey;
        }
      }
    }
  }

  .render-settings,
  .behaviour-settings {
    height: 100%;
    width: 100%;
    padding: 0 10px;
    background: var(--background-color);
  }
}