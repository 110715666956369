@import 'styles/variables';

.assets-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  position: relative;

  &__items {
    // 2px top for the shadow when asset is hovered
    padding: 16px 10px 2px 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
  }

  &__header {
    padding: 10px 15px 0px 15px;
    position: relative;
    z-index: 2;
    font-size: 12px;
    text-align: center;
    line-height: 1.1;
    cursor: url('../../assets/cursors/drag-cursor.svg'), grabbing;

    &.row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      & .search-input {
        width: 100%;
        padding: 0 10px;
      }
    }
  }

  .scroll-container {
    flex-grow: 1;
  }

  &__uploader {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;

    &--file {
      top: 16px;

      .file-input {
        &__wrap {
          .input-container {
            width: 100%;
            max-width: 184px;
            height: 134px;
            display: flex;
            align-items: flex-start;
            margin-left: 5px;

            &__body {
              border-radius: 8px;
              border: 1px dashed $media-text-lightgray;
              padding: 5px;
              min-height: 115px;
              width: 184px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }

          display: flex;
          text-align: left;
          min-height: 100%;
          max-height: 100%;
          border-radius: 0;
          justify-content: flex-start;
          width: 100%;

          svg {
            width: 24px;
            height: 24px;

            display: block;
            margin: 0 auto 8px;
          }

          p {
            text-align: center;
            max-width: 170px;
          }
        }
      }
    }

    &--full {
      top: 0;

      .file-input {
        &__wrap {
          height: calc(100% - 20px);
          margin: 0 20px 0px;
          font-size: 18px;

          border-radius: 8px;
          border: 1px dashed $media-text-lightgray;

          svg {
            width: 44px;
            height: 44px;
            margin-bottom: 12px;
          }

          p {
            font-size: 12px;
            max-width: unset;
          }
        }
      }
    }

    .import-anchor {
      color: $blue;
      white-space: nowrap;
      word-break: keep-all;
    }

    .file-input {
      width: 100%;
      height: 100%;
    }

    &-placeholder {
      width: 204px;
      height: 134px;
      position: relative;
      z-index: -1;
      padding: 0 10px 16px;
    }
  }
  &__selectable-group{
    height: 100%;
  }
}