.disableFocusStyle {
  input:focus {
    background: transparent;
    border: none;
  }
}

.root {
  height: auto;
  min-height: auto;
}

.list {
  padding-top: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  > * + * {
    // margin-left: 10px;
  }
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  flex: 1
}

.imgSelectors {
  display: flex;
  row-gap: 8px;
  column-gap: 14px;
}

.imgSelectorsColumn {
  flex-wrap: wrap;

}

.imgSelectorsRow {
  flex-wrap: nowrap;
}

.imagesSearch {
  composes: disableFocusStyle;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  margin-left: 0px;
  height: 24px;
  width: 100%;

  input {
    border: none;
  }
}

.imagesSearchCombo {
  width: 100%;
}

.placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 12px;
  }
  .desc {
    font-size: 16px;
    color: var(--text-color-light);
  }
}

.counter {
  margin-top: 24px;
  font-size: 14px;
  color: var(--text-color-light);
}

.multiFilter {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  width: 100%;
  gap: 8px;
}

.dropdownSource {
  width: 184px;

  &::before {
    content: '';
    width: 1px;
    height: 22px;
    background: var(--border-color-light);
  }

  input {
    padding-left: 12px;
  }

  > *:last-child {
    margin-left: -25px !important;
  }
}

.dropdownContainer {
  width: 184px;
}

.sourceInput {

  svg {
    margin-left: 10px;
  }

  input {
    width: 184px;
    cursor: pointer;
    display: block;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    height: 24px;
    background-color: transparent;
    outline: none;
    font-size: 12px;
    color: var(--text-color);
    padding: 0 25px 0 28px;
    transition: all 0.3s;
    margin-left: -23px;
  }
}

.dropdownItemText{
  margin-left: 5px;
}

.inputSearch {
  margin: 0;
  width: 184px;
}